import { React, useEffect, useState } from 'react';
import { checkStatus, parseJSON } from "../../utils/apiUtils";
import { device } from '../device';
import styled from 'styled-components';
import Button from './Button';
import Loader from '../Loader';

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const headers = { "Content-Type": "application/json" };

const Gallery = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Overlay = styled.div`
    position: absolute;
    top: 40px;
    opacity: 0;
    transition: opacity 0.3s;
`;

const ServiceName = styled.p`
    z-index: 1;
    transition: color 0.2s;
    text-align: left;
`;

const ServiceWrapper = styled.div`
    width: calc(100% / 6);
    position: relative;
    padding-top: calc(100% / 6);
    overflow: hidden;

    &:hover ${Overlay} {
        opacity: 1;
    }

    &:hover img {
        opacity: 0.5;
        filter: blur(1em);
    }

    &:hover ${ServiceName} {
        color: ${props => props.theme.color.green};
    }

    @media ${device.laptopL} {
        width: calc(100% / 4);
        padding-top: calc(100% / 4);
    }

    @media ${device.laptop} {
        width: calc(100% / 3);
        padding-top: calc(100% / 3);
    }

    @media ${device.tablet} {
        width: 50%;
        padding-top: 50%;
    }

    @media ${device.mobileL} {
        width: 100%;
        padding-top: 100%;
    }

    p {
        margin: 0;
        text-align: left;
        z-index: 1;
        white-space: pre-line;
        font-size: 1.2em;
    }
`;

const Service = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em 1em 0 1em;
    text-align: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    img {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        height: 80%;
        transition: opacity 0.3s, filter 0.3s;
        width: 100%;
    }
`;

export default function Services({ nodes }) {
    const [services, setServices] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const preview = nodes.preview;
    const servicesCategory = nodes.category;

    useEffect(() => {
        fetch(`${strapiUrl}/api/services?populate=*`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setServices(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }
    return (
        <section className='services' id={servicesCategory}>
            <Gallery>
                {
                    services.map((service, index) => {
                        if (service.attributes.category === servicesCategory) {
                            return (
                                <ServiceWrapper key={index}>
                                    <Service
                                        style={{
                                            backgroundImage: `radial-gradient(white, ${'#' + service.attributes.hex + '40' || 'rgba(64, 82, 19, 0.2)'})`
                                        }}
                                    >
                                        <ServiceName>{service.attributes.service}</ServiceName>
                                        <img width={50} src={strapiUrl + service.attributes.tree.data.attributes.url} alt={service.attributes.service} />
                                        <Overlay>
                                            <p>{service.attributes.servicesList}</p>
                                        </Overlay>
                                    </Service>
                                </ServiceWrapper>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </Gallery>
            {preview && <Button nodes={{ link: '/services' }}>View all services</Button>}
        </section>
    )
}