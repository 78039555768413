import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../device';
import Loader from '../Loader';
import { checkStatus, parseJSON } from "../../utils/apiUtils";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const headers = { "Content-Type": "application/json" };

const BrandSection = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5em;
`;

const Brand = styled.div`
    flex-basis: calc(100% / 8 - 1.5em);
    text-align: center;

    img {
        width: 5em;
        filter: grayscale(100%);
        transition: filter 0.3s;
    }

    &:hover img {
        filter: grayscale(0%);
    }

    @media ${device.tablet} {
        flex-basis: calc(100% / 4 - 1.5em);
    }

    @media ${device.mobileS} {
        flex-basis: calc(50% - 1.5em);
    }
`;
export default function Brands({ nodes }) {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const categoryFilter = nodes.category.data ? '&filters[categories][category][$eq]=' + nodes.category.data.attributes.category : '';
        fetch(`${strapiUrl}/api/clients?populate=*&sort=order:asc${categoryFilter}`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setClients(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, [nodes.category.data]);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <BrandSection className='container'>
            {
                clients.map((client, index) => {
                    if (nodes.category.data || client.attributes.order) {
                        return (
                            <Brand key={index}>
                                <img src={strapiUrl + client.attributes.logo.data.attributes.url} alt={client.attributes.name} />
                            </Brand>
                        )
                    } else {
                        return null;
                    }
                })
            }
        </BrandSection>
    )
}