import styled from "styled-components"
import { device } from "../device"
import ReadMore from "./ReadMore";

const StyledTestimonial = styled.div`
    display: flex;
    gap: 2em;
    align-items: start;
    padding: 1.5em;
    background-color: ${props => props.theme.color.semiGray};
    border-radius: 0.6em;

    img {
        width: 7em;
        border-radius: 0.6em;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    }

    p {
        margin: 0;
    }

    hr {
        border: none;
        border-top: 1px solid ${props => props.theme.color.gray};
        width: 70%;
        margin: 1em 0;
        transition: 1s;
    }

    &:hover hr {
        width: 100%;
    }

    @media ${device.tablet} {
        flex-wrap: wrap;
    }
`;

const Name = styled.span`
    color: ${props => props.theme.color.green};
`;

export default function Testimonial({ avatarUrl, name, position, client, quote, project }) {
    const readMoreButton = project.data && {
        link: '/work/' + project.data.attributes.slug,
        text: 'View project',
    }

    return (
        <StyledTestimonial>
            <img src={avatarUrl} alt={name} />
            <div style={{ width: '100%' }}>
                <p>
                    <Name>{name}</Name> · {position} at {client}
                </p>
                <hr />
                {quote.excerpt ? <ReadMore nodes={{full: <p>{quote.full}</p>, excerpt: <p>{quote.excerpt}</p>}} button={readMoreButton} /> : <p>{quote.full}</p>}
            </div>
        </StyledTestimonial>
    )
}