import styled from "styled-components";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const MediaContainer = styled.section`
    ${props => props.container ?
        `
            margin: 2em auto;
        `
        :
        `
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5em auto !important;
        `
    }
`;

export default function Media({ nodes, className }) {

    const { url, mime, alternativeText: alt } = nodes.media.data.attributes;
    const container = nodes.container ? true : false;
    const customClass = nodes.class ? nodes.class : '';

    return (
        <MediaContainer className={container ? 'container' : null}>
            {
                mime.includes('image') ?
                    <img style={{ borderRadius: container ? '0.6em' : 0 }} src={strapiUrl + url} alt={alt} className={`${className || ''} ${customClass}`} />
                    :
                    mime.includes('video') ?
                        <video style={{ width: '100%', borderRadius: container ? '0.6em' : 0 }} muted loop autoPlay playsInline className={className}>
                            <source src={strapiUrl + url} type={mime} />
                        </video>
                        :
                        null
            }
        </MediaContainer>
    )
}