import { React, useEffect, useState, useRef, useCallback } from 'react';
import { checkStatus, parseJSON } from "../../utils/apiUtils";
import { Link, useSearchParams } from 'react-router-dom';
import Media from './Media';
import { device } from '../device';
import styled from 'styled-components';
import Button from './Button';
import { BsCaretDownFill } from 'react-icons/bs';
import Loader from '../Loader';

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const headers = { "Content-Type": "application/json" };

const Gallery = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const StyledMedia = styled(Media)`
    min-width: 101%;
    min-height: 101%;
    object-fit: cover;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 100%;
    color: white;
    padding: 1em;
    gap: 2em;

    p {
        color: white;
        font-size: 1.2em;
    }
`;

const CategoryButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 0.1s;
    text-align: left;
    font-size: 1em;
`;

const Categories = styled.div`
    background-color: white;
    z-index: 100;
    transition: height 0.3s;
    overflow: hidden;

    ul {
        margin: 0;
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
`;

const Category = styled.li`
    padding: 0.5em 1em;
    cursor: pointer;
    transition: 0.2s;
    background-color: ${props => props.$active ? props.theme.color.gray : 'transparent'};

    button {
        color: ${props => props.$active ? 'white' : props.theme.color.gray};
    }

    &:hover {
        background-color: ${props => props.theme.color.green};
    }

    &:hover button {
        color: white;
    }

    &:active button {
        transform: scale(0.95);
    }
`;

const BrowseCategories = styled.div`
`;

export const GalleryItem = styled(Link)`
    height: 500px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: calc(100% / 6);

    &:hover ${Overlay} {
        opacity: 1;
    }

    &:hover ${Overlay} hr {
        width: 25%;
        transition-delay: 0.1s;
    }

    @media ${device.laptopL} {
        width: calc(100% / 4);
    }

    @media ${device.laptop} {
        width: calc(100% / 3);
    }

    @media ${device.tablet} {
        width: 50%;
    }

    @media ${device.mobileL} {
        width: 100%;
    }
`;

const StyledHR = styled.hr`
    width: 0px;
    border: none;
    border-bottom: white 0.1em solid;
    transition: 0.2s;
    margin: 0;
`;

const unfoldButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    justifyContent: 'center',
};

function slugify(string) {
    return string.toLowerCase().replace(/\s+/g, '').replace(/&/g, 'and');
}

export default function ProjectGalllery({ nodes }) {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    const [categoriesError, setCategoriesError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [unfolded, setUnfolded] = useState(false);
    const categoriesRef = useRef(null);
    const [categoriesHeight, setCategoriesHeight] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeCategory, setActiveCategory] = useState('all');

    useEffect(() => {
        const category = searchParams.get('category');
        if (category) {
            setActiveCategory(category);
        }
    }, [searchParams]);

    const preview = nodes.preview;
    const browseCategories = nodes.browseCategories;
    const projectsToShow = preview ? 12 : projects.length;

    projects.splice(projectsToShow);

    useEffect(() => {
        fetch(`${strapiUrl}/api/projects?sort[0]=order:asc&populate[thumbnail][fields][0]=url&populate[thumbnail][fields][1]=alternativeText&populate[thumbnail][fields][2]=mime&populate[clients][fields][0]=name&populate[clients][fields][1]=description&populate[categories][fields][0]=category`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setProjects(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });

        if (browseCategories) {
            fetch(`${strapiUrl}/api/categories`, { headers, method: "GET" })
                .then(checkStatus)
                .then(parseJSON)
                .then(({ data }) => {
                    const categories = [{ render: 'All', param: 'all' }];
                    data.forEach((category) => {
                        categories.push({ render: category.attributes.category, param: slugify(category.attributes.category) });
                    });
                    setCategories(categories);
                    setCategoriesLoading(false);
                })
                .catch((error) => {
                    setCategoriesError(error);
                });
        } else {
            setCategoriesLoading(false);
        }

        if (searchParams.get('category')) {
            setUnfolded(true);
        }
    }, [searchParams, browseCategories]);

    useEffect(() => {
        if (browseCategories) {
            if (categoriesRef.current) {
                setCategoriesHeight(categoriesRef.current.offsetHeight);
            }

            const handleResize = () => {
                setCategoriesHeight(categoriesRef.current.offsetHeight);
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [categories, browseCategories]);

    const toggle = useCallback(() => {
        setUnfolded((prevUnfolded) => !prevUnfolded);
    }, []);

    if (error) {
        return <div>An error occurred while fetching projects: {error.message}</div>;
    }

    if (categoriesError) {
        return <div>An error occurred while fetching categories: {categoriesError.message}</div>;
    }

    if (loading || categoriesLoading) {
        return <Loader />;
    }

    return (
        <section className='projects'>
            {
                browseCategories &&
                <BrowseCategories>
                    <Button buttonStyle={unfoldButtonStyle} onClick={toggle}>
                        Browse categories
                        <BsCaretDownFill style={{ transform: unfolded ? 'rotate(180deg)' : 'rotate(0)', transition: '0.3s' }} />
                    </Button>
                    <Categories style={{ height: unfolded ? categoriesHeight : 0 }}>
                        <ul ref={categoriesRef}>
                            {categories.map((category, index) => (
                                <Category
                                    key={index}
                                    $active={category.param === activeCategory}
                                    onClick={() => {
                                        setActiveCategory(category.param);
                                        setSearchParams({ category: category.param });
                                    }}
                                >
                                    <CategoryButton>{category.render}</CategoryButton>
                                </Category>
                            ))}
                        </ul>
                    </Categories>
                </BrowseCategories>
            }
            <Gallery>
                {
                    projects.map((project, index) => {
                        if (activeCategory === 'all' || project.attributes.categories.data.some(category => slugify(category.attributes.category) === activeCategory)) {

                            const formatClients = (clients) => {
                                if (clients.length === 0) return '';
                                const names = clients.map((client) => client.attributes.name);

                                if (names.length === 1) return names[0];

                                const lastName = names.pop();
                                return `${names.join(', ')} & ${lastName}`;
                            }

                            return (
                                <GalleryItem to={`/work/${project.attributes.slug}`} key={index}>
                                    <StyledMedia
                                        nodes={{
                                            media: {
                                                data: {
                                                    attributes: {
                                                        mime: project.attributes.thumbnail.data.attributes.mime,
                                                        url: project.attributes.thumbnail.data.attributes.url,
                                                        alt: project.attributes.thumbnail.data.attributes.alternativeText
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                    <Overlay>
                                        <p>{project.attributes.description}</p>
                                        <StyledHR />
                                        <p>{formatClients(project.attributes.clients.data)}</p>
                                    </Overlay>
                                </GalleryItem>
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </Gallery>
            {preview && <Button nodes={{ link: '/work' }}>View all projects</Button>}
        </section>
    )
}