import Testimonial from "./Testimonial"

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

export default function SingleTestimonial({ nodes }) {
    const attr = nodes.testimonial.data.attributes;

    return (
        <div className="container" style={{margin: '2em auto'}}>
            <Testimonial avatarUrl={strapiUrl + attr.avatar.data.attributes.url} name={attr.name} position={attr.position} client={attr.client.data.attributes.name} quote={nodes.quote} project={attr.project} />
        </div>
    )
}